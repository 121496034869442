<template>
  <div class="container-fluid">
    <div class="row d-flex flex-column flex-md-row flex-lg-row">
      <div class="col-2"></div>
      <div class="col-lg-4 col-md-4 col-12 mt-5 mt-lg-0 mt-md-0 " >
        <div 
        class="d-flex flex-column"
        v-motion 
        :initial="{
          y: -300,
          opacity: 0,
        }"
        :enter="{
          y: 0,
          opacity: 1,
          transition: {
            y: {
              delay: 10

            },
            opacity: {
            },
          },
        }">
          <div class="mt-lg-0 mt-md-0 mt-5">
            <div>
              <h1 class="text-white mt-5 mt-lg-0 mt-md-0">DON'T BE SHY!</h1>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
              <span>
                If you have any questions or want to discuss your project, please contact me through my social media or via this form. Provide all the information you deem appropriate. I'll be delighted to talk to you.
              </span> 
            </div>

          </div>
          <br>
          <div class="d-flex mb-3 ">
            <div>
              <i class="bi bi-linkedin text-warning fs-1"></i>
            </div>
            <div class="col-1"></div>
            <div class="">
              <h5 class="text-white">Linkedin</h5>
              <a href="https://www.linkedin.com/in/valentino-ghitti-b6042120a/">
                linkedin.com/in/valentino-ghitti
              </a>
            </div>
          </div>
          <div class="d-flex d-flex mb-3">
            <div>
              <i class="bi bi-github text-warning fs-1"></i>
            </div>
            <div class="col-1"></div>
            <div>
              <h5 class="text-white">Github</h5>
              <a href="https://github.com/ValentinoGhitti">
                github.com/ValentinoGhitti
              </a>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <i class="bi bi-envelope-fill text-warning fs-1"></i>
            </div>
            <div class="col-1"></div>
            <div>
              <h5 class="text-white">Email</h5>
              <a href="mailto:valentinoghitti00@hotmail.com">
                Valentinoghitti00@hotmail.com
              </a>
            </div>
          </div>

        </div>
      </div>
      <br>
      <form name="my-form" action="https://formsubmit.co/valentinoghitti00@hotmail.com" method="POST" class="col-md-5 col-lg-5 col-12" >
        <div class="d-flex">
          <div class="col-xl-5">
            <div class="mb-3">
              <label for="name" class="form-label text-light">Name</label>
              <input type="text" name="name" class="form-control form-control-" required id="name" placeholder="John Doe">
            </div>
          </div>
          <div class="col-2"></div>
          <div class="col-xl-5">
            <div class="mb-3">
              <label for="email" class="form-label text-light">Email</label>
              <input type="email" name="email" class="form-control form-control-" required id="email" placeholder="Johndoe@hotmail.com">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="case" class="form-label text-light">Subject</label>
          <input type="text" name="subject" class="form-control form-control-" id="subject" placeholder="Your subject">
        </div>
        <div class="mb-3">
          <label for="message" class="form-label text-light">Message</label>
          <textarea class="form-control form-control-lg rounded" name="message" required id="message" placeholder="Your message" rows="3"></textarea>
        </div>  
        <div class="d-flex column">
          <button type="submit" class="btn10 rounded d-flex justify-content-start">SEND</button>
        </div>
      </form>
    </div>
  </div>
</template>

<style>

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: #fcb206;
  box-shadow: 0 1px 1px #fcb206;
  outline: 0 none;
}

.form-control,
.form-control:active,
.form-control:focus,
.form-control:focus:active {
  background-color: #272828;
  background: #272828;
  border: none;
  color: white;
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline:0px none !important;
  -webkit-appearance:none;
  box-shadow: 0 1px 1px #fcb206 !important;
  border-color: #fcb206 !important;
  box-shadow: 0 1px 1px #fcb206;
}

a{
  text-decoration: none;
  color: white;
}

a:hover {
  color: #fcb206;
}
</style>